import { template as template_92122b5916c944e1b70d4addfe3da265 } from "@ember/template-compiler";
const EmptyState = template_92122b5916c944e1b70d4addfe3da265(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
