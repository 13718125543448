import { template as template_df5c6b077f28414aa4c51c131aeb8e08 } from "@ember/template-compiler";
const FKText = template_df5c6b077f28414aa4c51c131aeb8e08(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
