import { template as template_523ae2e76a6147238832ab73f5b06f11 } from "@ember/template-compiler";
const FKControlMenuContainer = template_523ae2e76a6147238832ab73f5b06f11(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
